<template>
  <div class="project-sales-report--detail">
    <div class="container fluid">
      <div class="card mb-4 p-2">
        <div class="d-flex align-items-center">
          <fd-button
            class="circle"
            @click="$router.replace({ name: 'ManageProjects' })"
          >
            <i class="fas fa-chevron-left"></i
          ></fd-button>
          <h2 class="m-2">Project Sales Detail</h2>
        </div>
        <!-- Project Details -->
        <div class="m-2 project-info">
          <div class="project-info__name">{{ project.name }}</div>
          <div class="project-info__developer">
            {{ project.developer.name }}
          </div>
        </div>
        <div class="d-flex m-2">
          <fd-button class="bordered main" @click="toggleReport">{{
            getToggleReportLabel()
          }}</fd-button>
        </div>
      </div>

      <component :is="activeReportComponent"> </component>
    </div>
  </div>
</template>

<script>
import ProjectAPI from "@/modules/Project/api/project";
const projectAPI = new ProjectAPI("agency");
import { numberFormat, numberWithCommas } from "@/utils/string";

const reportMode = {
  SALES: "sales",
  AGING: "aging"
};

export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {
      project: {
        developer: {}
      },

      activeReportMode: reportMode.SALES,
      reportMode,

      numberFormat,
      numberWithCommas
    };
  },
  computed: {
    activeReportComponent() {
      switch (this.activeReportMode) {
        case reportMode.SALES:
          return () => import("@/components/SalesReport/ProjectSalesReport");
        case reportMode.AGING:
          return () => import("@/components/SalesReport/ProjectAgingReport");
        default:
          return () => import("@/components/SalesReport/ProjectSalesReport");
      }
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        await Promise.all([this.getProjectDetail()]);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },
    getToggleReportLabel() {
      switch (this.activeReportMode) {
        case reportMode.SALES:
          return "Aging Report";
        case reportMode.AGING:
          return "Sales Report";

        default:
          break;
      }
    },
    toggleReport() {
      switch (this.activeReportMode) {
        case reportMode.SALES:
          this.activeReportMode = reportMode.AGING;
          break;
        case reportMode.AGING:
          this.activeReportMode = reportMode.SALES;
          break;

        default:
          break;
      }
    },

    async getProjectDetail() {
      try {
        let data = await projectAPI.getProject(this.$route.params.id);
        this.project = this._.cloneDeep(data);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get project details. Please try again later."
        });
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.project-sales-report--detail {
  .project-info {
    .project-info__name {
      font-size: 18px;
      font-weight: bold;
      color: #444;
    }
    .project-info__developer {
      font-weight: normal;
      color: #888;
    }
  }
  .plot {
    background: #ddd;
    color: #444;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
  }
}
</style>
